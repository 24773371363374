import React, { useState } from "react"
import { navigate } from "gatsby"
import { Link } from "gatsby-plugin-modal-routing"

import {
  ContentContainer,
  Col,
  Title,
  Description,
  ProjectsContainer,
  HeadingContainer,
  ViewAllContainer,
  ViewAll,
} from "./../styles/homeOurWorks"

import Project from "./../components/Project"

const HomeOurWorks = () => {
  const [videoLink, setVideoLink] = useState("")
  return (
    <ContentContainer>
      <HeadingContainer>
        <Col>
          <Title>Highlights</Title>
        </Col>
        <Col>
          <Description>
            Our capabilities include all aspects of Explainer Video Production,
            Animation, Animated Modules for e-learning, graphic design etc.
          </Description>
        </Col>
      </HeadingContainer>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ProjectsContainer>
          <Project type="type1" title="Stack Perks" id="ki_J76D8KSY" />
          <Project type="type2" title="Pokeman" id="ki_J76D8KSY" />
          <Project type="type3" title="Why explainer videos" id="2WclNScNVQM" />
          <Project type="type4" title="The Mars facts" id="8yD04Z5G1VQ" />
        </ProjectsContainer>
      </div>
      <ViewAllContainer>
        <ViewAll
          onClick={() => {
            setTimeout(() => {
              navigate("/works")
            }, 300)
          }}
        />
      </ViewAllContainer>
    </ContentContainer>
  )
}

export default HomeOurWorks
