import styled from "styled-components"

export const Banner = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px 1fr;

  @media (max-width: 599px) {
    height: auto;
    padding-bottom: 0;
    align-items: start;
    grid-template-columns: 100%;
  }
  @media (min-width: 600px) {
    height: auto;
    padding-bottom: 0;
    align-items: start;
    grid-template-columns: 1fr 60px 1fr;
  }
  @media (min-width: 900px) {
    height: auto;
    padding-bottom: 0;
    align-items: start;
  }
  @media (min-width: 1200px) {
    height: calc(100vh - 90px);
    padding-bottom: 100px;
    align-items: end;
  }
`

export const BannerTextContent = styled.div`
  position: relative;
  z-index: 1;
  grid-column: 1 / 3;
  grid-row: 1;

  @media (max-width: 599px) {
    padding-left: 20px;
    padding-right: 20px;
    grid-column: 1;
    grid-row: 2;
  }
  @media (min-width: 600px) {
    padding-left: 35px;
  }
  @media (min-width: 900px) {
    padding-left: 50px;
    padding-bottom: 0;
  }
  @media (min-width: 1200px) {
    padding-left: 72px;
    padding-bottom: 80px;
  }
`

export const BannerImageContent = styled.div`
  grid-column: 2 / 4;
  grid-row: 1;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: flex-end;

  .bannerImage {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 599px) {
    grid-column: 1;
    display: flex;
    justify-content: center;
    img {
      height: 80% !important;
      width: 80% !important;
      object-position: center center !important;
      margin-left: 10% !important;
    }
  }
  @media (min-width: 600px) {
    img {
      height: 90% !important;
      width: 90% !important;
      margin-left: 5% !important;
    }
  }

  @media (min-width: 900px) {
    img {
      height: 100% !important;
      width: 100% !important;
      margin-left: 0 !important;
    }
  }
`

export const BannerTitle = styled.h1`
  color: ${({ theme: { primary } }) => primary};
  margin-bottom: 2rem;
`
export const BannerDescription = styled.p.attrs({
  className: "p2",
})`
  color: ${({ theme: { text } }) => text};
`

export default {}
