import styled from "styled-components"
import Tilt from "react-tilt"

export const TiltContainer = styled(Tilt)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-shadow: 0;
  transform-style: preserve-3d;
`

export const ProjectContainer = styled.div`
  width: 100%;
  height: 100%;
  font-size: 100px;
  position: relative;

  p {
    color: ${({ theme: { primary } }) => primary};
    border-bottom: 3px solid ${({ theme: { primary } }) => primary};
    padding-bottom: 10px;
    position: absolute;
    z-index: 10;
    font-size: 1.5rem;
    font-weight: 700;
    right: -70px;
    bottom: 35px;
    max-width: 150px;
  }

  @media (max-width: 599px) {
    p {
      right: -10px;
    }
  }

  @media (min-width: 600px) {
    p {
      right: -25px;
    }
  }
  @media (min-width: 900px) {
    p {
      right: -45px;
    }
  }
  @media (min-width: 1200px) {
    p {
      right: -70px;
    }
  }
`

export const ProjectImage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
`

export default {
  projectImage: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
}
