import styled from "styled-components"
import { motion } from "framer-motion"

export const ContentContainer = styled.div`
  margin-top: 100px;
  margin-bottom: 90px;
  width: 100%;
  padding: 60px 50px 0;
  background: ${({ theme: { overlay } }) => overlay};
  border-radius: 28px;

  @media (max-width: 599px) {
    padding: 30px 0px;
    margin-top: 50px;
  }
  @media (min-width: 900px) {
    padding: 60px 50px 0;
  }
`

export const HeadingContainer = styled.div`
  display: flex;
  margin-bottom: 100px;
  text-align: center;

  @media (max-width: 599px) {
    flex-direction: column;
    margin-bottom: 50px;
    p {
      max-width: 100%;
      padding: 0 20px;
    }
  }
  @media (min-width: 600px) {
    flex-direction: column;
    margin-bottom: 50px;
    p {
      max-width: 100%;
      padding: 0 20px;
      text-align: center;
    }
  }
  @media (min-width: 900px) {
    flex-direction: row;
    margin-bottom: 100px;
    p {
      max-width: 80%;
      padding: 0 20px;
      text-align: left;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1800px) {
  }
`

export const Col = styled.div`
  flex: 1;
  width: 100%;
`
export const Title = styled.h1`
  text-align: center;
  font-weight: 700;
  font-size: 2.81rem;
  color: ${({ theme: { text2 } }) => text2};
  margin: 0;
`
export const Description = styled.p`
  max-width: 80%;
  margin: 0;
  color: ${({ theme: { text2 } }) => text2};
  font-size: 1.25rem;
  line-height: 30px;
`

export const ViewAllContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 90px;

  @media (max-width: 599px) {
    padding-bottom: 50px;
  }
`
export const ViewAll = styled(motion.button).attrs({
  children: "View all works",
  whileHover: {
    scale: 1.1,
  },
  whileTap: { scale: 0.95 },
})`
  box-shadow: none;
  height: 60px;
  padding: 0 38px;
  background: transparent;
  border: 1px solid ${({ theme: { primary } }) => primary};
  border-radius: 8px;
  font-size: 1.25rem;
  color: ${({ theme: { text } }) => text};
  outline: 0;

  &:hover {
    background: ${({ theme: { primary } }) => primary};
    color: #ffffff;
  }
`

export const ProjectsContainer = styled.div`
  display: grid;
  grid-template-columns: 25px repeat(4, 1fr) repeat(4, 3.125rem) repeat(4, 1fr);
  grid-template-rows: 3.625rem 18.312rem 6.25rem 5.93rem 6.25rem 18.125rem 6.25rem;
  width: 100%;
  margin-bottom: 90px;
  max-width: 1099px;

  .projectHolder:nth-child(1) {
    grid-column: 1/7;
    height: 21.87rem;
    width: 29rem;
  }

  .projectHolder:nth-child(2) {
    grid-column: 10/14;
    grid-row: 2/10;
    height: 30.5rem;
    width: 26rem;
  }
  .projectHolder:nth-child(3) {
    grid-column: 2/6;
    grid-row: 4/5;
    height: 30.5rem;
    width: 26rem;
  }
  .projectHolder:nth-child(4) {
    grid-column: 9/12;
    grid-row: 6/10;
    height: 29rem;
    width: 29rem;
  }

  @media (max-width: 1300px) {
    grid-template-columns: 25px repeat(4, 1fr) repeat(4, 2.5rem) repeat(4, 1fr);
    grid-template-rows: 3rem 17rem 6rem 5rem 6rem 17rem 6rem;

    .projectHolder:nth-child(1) {
      height: 20rem;
      width: 25rem;
    }

    .projectHolder:nth-child(2) {
      height: 28rem;
      width: 22rem;
    }
    .projectHolder:nth-child(3) {
      height: 28rem;
      width: 22rem;
    }
    .projectHolder:nth-child(4) {
      height: 25rem;
      width: 25rem;
    }
  }

  @media (max-width: 1055px) {
    grid-template-columns: 15px repeat(4, 1fr) repeat(4, 1.9rem) repeat(4, 1fr);
    grid-template-rows: 2rem 15rem 4rem 3rem 4rem 15rem 4rem;

    .projectHolder:nth-child(1) {
      height: 17rem;
      width: 23rem;
    }

    .projectHolder:nth-child(2) {
      height: 22rem;
      width: 20rem;
    }
    .projectHolder:nth-child(3) {
      height: 22rem;
      width: 20rem;
    }
    .projectHolder:nth-child(4) {
      height: 21rem;
      width: 23rem;
    }
  }

  @media (max-width: 855px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    row-gap: 60px;
    justify-items: center;
    margin-bottom: 80px;

    .projectHolder:nth-child(1) {
      grid-column: 1;
      grid-row: 1;
      height: 20rem;
      width: 23rem;
    }

    .projectHolder:nth-child(2) {
      grid-column: 1;
      grid-row: 2;
      height: 20rem;
      width: 23rem;
    }
    .projectHolder:nth-child(3) {
      grid-column: 1;
      grid-row: 3;
      height: 20rem;
      width: 23rem;
    }
    .projectHolder:nth-child(4) {
      grid-column: 1;
      grid-row: 4;
      height: 20rem;
      width: 23rem;
    }
  }
`
