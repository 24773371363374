import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// Components
import Layout from "../components/layout"
import HomeOurWorks from "../components/HomeOurWorks"
import Clients from "../components/Clients"
import Footer from "../components/Footer"
import SEO from "./../components/Seo"

// styled components
import { Container } from "./../styles/GlobalStyles"
import {
  Banner,
  BannerTextContent,
  BannerImageContent,
  BannerTitle,
  BannerDescription,
} from "./../styles/home"

const IndexPage = props => {
  const data = useStaticQuery(graphql`
    query {
      home: file(relativePath: { eq: "home.png" }) {
        childImageSharp {
          fluid(maxHeight: 550, maxWidth: 798, fit: INSIDE) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Branding, Storytelling and Digital Experiences"
        pathname={props.location.pathname}
      />
      <Banner>
        <BannerTextContent>
          <BannerTitle>
            Branding,
            <br />
            Storytelling &amp;
            <br />
            Digital Experiences.
          </BannerTitle>
          <BannerDescription>
            Mellow Frames is dedicated to passionately helping all those
            entrepreneurs gripped in anxiety, prior to a launch. We at Mellow
            Frames take every project as a challenge and we provide a unique
            design to each one of them.
          </BannerDescription>
        </BannerTextContent>
        <BannerImageContent>
          <Img
            fluid={data.home.childImageSharp.fluid}
            className="bannerImage"
          />
        </BannerImageContent>
      </Banner>
      <Container>
        <HomeOurWorks />
      </Container>
      <Clients />
      <Footer />
    </Layout>
  )
}

export default IndexPage
