import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby-plugin-modal-routing"

import styles, {
  TiltContainer,
  ProjectContainer,
  ProjectImage,
} from "./../styles/project"

const Project = props => {
  const { type = "type1", id = "" } = props

  const data = useStaticQuery(graphql`
    query {
      type1: file(relativePath: { eq: "type1_project.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 483, maxWidth: 416, fit: INSIDE) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      type2: file(relativePath: { eq: "type2_project.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 483, maxWidth: 416, fit: INSIDE) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      type3: file(relativePath: { eq: "type3_project.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 483, maxWidth: 416, fit: INSIDE) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      type4: file(relativePath: { eq: "type4_project.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 464, maxWidth: 439, fit: INSIDE) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const img = data[type]

  return (
    <div className="projectHolder">
      <Link to={`/VideoModal?videosrc=${id}`} asModal>
        <TiltContainer>
          <ProjectContainer>
            <p>{props.title}</p>
            <ProjectImage>
              {!img ? null : (
                <Img
                  fluid={img.childImageSharp.fluid}
                  style={styles.projectImage}
                  imgStyle={styles.projectImage}
                />
              )}
            </ProjectImage>
          </ProjectContainer>
        </TiltContainer>
      </Link>
    </div>
  )
}

export default Project
